import { getReportData } from '@/api/methods/reports/reports';

export function getMicrophoneStat(tree, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/microphone/sum?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersTree: tree };

  return getReportData(link, object);
}

export function getMicrophoneDetails(array, dateFrom, dateTo, worktimeOnly) {
  const link = `/reports/microphone/details?dateFrom=${dateFrom}&dateTo=${dateTo}&worktimeOnly=${worktimeOnly}`;
  const object = { computersArray: array };

  return getReportData(link, object);
}
