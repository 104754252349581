<template>
  <div>
    <table :class="$style.table">
      <thead>
        <tr :class="$style.header">
          <td
            v-for="column in columns"
            :key="column.name"
            @click="changeSort(column)"
          >
            <div :class="$style.tdInline">
              {{ column.name }}
            </div>
            <img
              src="@/assets/images/icons/table/sort-arrow.svg"
              :class="[
                $style.tdInline,
                $style.arrow,
                {
                  [$style.asc]: column.sort == 2,
                  [$style.hidden]: column.sort == 0,
                },
              ]"
            >
          </td>
        </tr>
      </thead>

      <tbody
        v-for="(department, index) in sortedDeps"
        :key="index"
      >
        <tr
          v-show="department.isVisible"
          :class="$style.item"
          @contextmenu.prevent="showMenu($event, department)"
        >
          <td
            :style="indent(department.depth)"
            :class="[
              { [$style.department]: isFolder(department) },
              { [$style.user]: !isFolder(department) },
            ]"
            @click="expand(department)"
          >
            <div
              v-if="isFolder(department)"
              :class="[
                $style.expandButton,
                { [$style.expanded]: department.isExpanded },
              ]"
            />

            <img
              v-if="isFolder(department)"
              :class="$style.iconFolder"
              src="@/assets/images/icons/computer/folder.svg"
            >

            <div v-if="isFolder(department)">
              {{ department.name }}
            </div>

            <div v-if="!isFolder(department)">
              {{ department.nameUser }}
            </div>
          </td>
          <td
            v-for="(column, number) in columns.slice(1)"
            :key="column.field + index + number"
          >
            {{ getOutput(department[reportData][column.field], column.field) }}
          </td>
        </tr>
      </tbody>
    </table>
    <context-menu
      v-show="isMenuVisible"
      id="contextmenu"
      :user-id="selectedUser"
      :mouse-x="mouseX"
      :mouse-y="mouseY"
      :date-from="dateRange.dateFrom"
      :date-to="dateRange.dateTo"
      :is-worktime-only="isWorktimeOnly"
      :pages="pages"
    />
  </div>
</template>

<script>
import { convertSecondsToDays } from '@/helpers/format-date';
import ContextMenu from '@/components/common/contextmenu/custom-contextmenu.vue';

export default {
  components: { ContextMenu },
  props: ['columns', 'reportTree', 'reportData', 'isWorktimeOnly'],
  data: () => ({
    tableArray: [],
    exportArray: [],
    selectedUser: null,
    mouseX: null,
    mouseY: null,
    isMenuVisible: false,
  }),
  computed: {
    pages() {
      if (this.reportData === 'messengers') {
        return [
          {
            name: 'detailsMessengers',
            text: 'Отчет-детализация мессенджеры',
          },
        ];
      }
      if (this.reportData === 'microphoneSum') {
        return [
          {
            name: 'microphoneDetails',
            text: 'Детализированный отчет Записи с микрофона',
          },
        ];
      }
      return null;
    },
    sortedDeps() {
      const calc = this.reportTree.slice(0).sort(this.compare);

      calc.forEach((element) => {
        if (element.type === 'folder') this.sortNodes(element.children);
      });

      this.updateTableArray(calc);
      if (this.tableArray.length > 0) this.makeExportArray();
      return this.tableArray;
    },
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
  },
  methods: {
    makeExportArray() {
      this.exportArray = [];
      this.tableArray.forEach((item) => {
        const resObj = {};
        if (item.type !== 'folder') {
          for (let j = 0; j < this.columns.length; j++) {
            const { field } = this.columns[j];
            if (field === 'name') resObj[this.columns[j].name] = item.nameUser;
            else {
              const res = item[this.reportData][field];
              if (!res) resObj[this.columns[j].name] = 0;
              else if (field === 'duration') resObj[this.columns[j].name] = convertSecondsToDays(res);
              else {
                resObj[this.columns[j].name] = res;
              }
            }
          }
          this.exportArray.push(resObj);
        }
      });
      let name = '';
      switch (this.reportData) {
        case 'messengers': {
          name = 'Отчет по использованию мессенджеров';
          break;
        }
        case 'mail': {
          name = 'Отчет по использованию почты';
          break;
        }
        case 'microphoneSum': {
          name = 'Суммарный отчет по микрофону';
          break;
        }
        default: name = '';
      }
      this.$store.commit('exportData/setInternetTable',
        { type: this.reportData, data: { name, data: this.exportArray } });
    },
    showMenu(event, user) {
      if (this.reportData !== 'mail' && !this.isFolder(user)) {
        this.selectedUser = user.id;
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
        this.isMenuVisible = true;

        window.addEventListener('click', (e) => {
          if (!document.getElementById('contextmenu').contains(e.target)) {
            this.isMenuVisible = false;
            window.removeEventListener('click', e);
          }
        });
      }
    },
    changeVisibility(item, bool) {
      item.isVisible = bool;
      if (item.type === 'folder' && item.isExpanded === bool && item.children) {
        item.children.forEach((child) => this.changeVisibility(child, bool));
      }
    },
    expand(item) {
      if (item.type === 'folder') {
        item.isExpanded = !item.isExpanded;

        if (item.children) {
          item.children.forEach((child) => this.changeVisibility(child, item.isExpanded));
        }
      }
    },
    indent(depth) {
      return {
        paddingLeft: `${depth * 10}px`,
      };
    },
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    getOutput(data, field) {
      if (!data) return 0;

      if (field === 'activePercent') return data.toFixed(2);
      if (field === 'worktime' || field === 'duration') return this.convertSecondsToDays(data);
      return data;
    },
    isFolder(node) {
      if (node.type === 'folder') return true;
      return false;
    },
    changeSort(column) {
      const { sort } = column;

      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    updateTableArray(calc) {
      this.tableArray = [];
      calc.forEach((node) => this.generateArrayFromTree(node, 1));
    },
    generateArrayFromTree(node, depth) {
      node.depth = depth;

      this.tableArray.push(node);

      if (node.type === 'folder') {
        node.children.forEach((child) => this.generateArrayFromTree(child, depth + 1));
      }
    },
    sortNodes(nodes) {
      nodes.sort(this.compare);

      nodes.forEach((node) => {
        if (node.type === 'folder') this.sortNodes(node.children);
      });
    },
    compare(a, b) {
      const index = this.columns.findIndex((x) => x.sort > 0);
      const key = this.columns[index].field;
      let a1 = a;
      let b1 = b;

      if (key === 'name') {
        if (a1.type === 'folder') a1 = a1[key].toLowerCase();
        else a1 = a1.nameUser.toLowerCase();
        if (b1.type === 'folder') b1 = b1[key].toLowerCase();
        else b1 = b1.nameUser.toLowerCase();
      } else {
        a1 = a[this.reportData][key];
        b1 = b[this.reportData][key];
      }

      if (this.columns[index].sort === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 7px 4px 4px 10px;
    cursor: pointer;
  }
}

.tdInline {
  display: inline-flex;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.item {
  height: 29px;
  cursor: pointer;
  border-bottom: 0.2px solid #eee;

  &:hover {
    background: $light-gray;
  }

  td {
    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    &.user {
      font-size: 14px;
      letter-spacing: 0px;
      text-align: left;
      padding-top: 7px;
      padding-right: 4px;
      padding-bottom: 7px;
    }

    &.department {
      font-weight: 500;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      padding: 7px 4px 7px 1px;
      display: flex;
      position: relative;
      border-right: 0px;
    }
  }
}

.iconFolder {
  padding-right: 5px;
}

.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 1;
  margin-left: -17px;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
</style>
