var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{class:_vm.$style.table},[_c('thead',[_c('tr',{class:_vm.$style.header},_vm._l((_vm.columns),function(column){
var _obj;
return _c('td',{key:column.name,on:{"click":function($event){return _vm.changeSort(column)}}},[_c('div',{class:_vm.$style.tdInline},[_vm._v(" "+_vm._s(column.name)+" ")]),_c('img',{class:[
              _vm.$style.tdInline,
              _vm.$style.arrow,
              ( _obj = {}, _obj[_vm.$style.asc] = column.sort == 2, _obj[_vm.$style.hidden] = column.sort == 0, _obj ) ],attrs:{"src":require("@/assets/images/icons/table/sort-arrow.svg")}})])}),0)]),_vm._l((_vm.sortedDeps),function(department,index){
            var _obj, _obj$1, _obj$2;
return _c('tbody',{key:index},[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(department.isVisible),expression:"department.isVisible"}],class:_vm.$style.item,on:{"contextmenu":function($event){$event.preventDefault();return _vm.showMenu($event, department)}}},[_c('td',{class:[
            ( _obj = {}, _obj[_vm.$style.department] = _vm.isFolder(department), _obj ),
            ( _obj$1 = {}, _obj$1[_vm.$style.user] = !_vm.isFolder(department), _obj$1 ) ],style:(_vm.indent(department.depth)),on:{"click":function($event){return _vm.expand(department)}}},[(_vm.isFolder(department))?_c('div',{class:[
              _vm.$style.expandButton,
              ( _obj$2 = {}, _obj$2[_vm.$style.expanded] = department.isExpanded, _obj$2 ) ]}):_vm._e(),(_vm.isFolder(department))?_c('img',{class:_vm.$style.iconFolder,attrs:{"src":require("@/assets/images/icons/computer/folder.svg")}}):_vm._e(),(_vm.isFolder(department))?_c('div',[_vm._v(" "+_vm._s(department.name)+" ")]):_vm._e(),(!_vm.isFolder(department))?_c('div',[_vm._v(" "+_vm._s(department.nameUser)+" ")]):_vm._e()]),_vm._l((_vm.columns.slice(1)),function(column,number){return _c('td',{key:column.field + index + number},[_vm._v(" "+_vm._s(_vm.getOutput(department[_vm.reportData][column.field], column.field))+" ")])})],2)])})],2),_c('context-menu',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuVisible),expression:"isMenuVisible"}],attrs:{"id":"contextmenu","user-id":_vm.selectedUser,"mouse-x":_vm.mouseX,"mouse-y":_vm.mouseY,"date-from":_vm.dateRange.dateFrom,"date-to":_vm.dateRange.dateTo,"is-worktime-only":_vm.isWorktimeOnly,"pages":_vm.pages}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }